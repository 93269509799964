// const url = "http://localhost:2138/api/v1";  //Naman
// const url = "http://172.16.6.83:2138/api/v1"; //izahar
// const url = "http://172.16.6.125:2138/api/v1"; //naman
const url = "https://node-mobsdk.mobiloitte.io/api/v1";
const ApiConfig = {
  url,
  //Login Auth
  login: `${url}/user/userLogin`,
  otp: `${url}/user/resendOTP`,
  verifyOTP: `${url}/user/verifyOTP`,
  resetPassword: `${url}/user/resetPassword`,
  changePassword: `${url}/user/changePassword`,
  getAdminProfile: `${url}/user/getUserDetails`,
  //User management
  listAllUsers: `${url}/admin/listAllUsers`,
  updateAdmin: `${url}/user/updateUserDetails`,
  createSubAdmin: `${url}/user/signUp`,

  uploadFile: `${url}/user/uploadFile`,
  getStaticContent: `${url}/staticContent/getOneStaticContent`,
  getAllStaticContent: `${url}/staticContent/getAllStaticContent`,
  editStaticContent: `${url}/staticContent/updateStaticContent`,
  createStatic: `${url}/staticContent/createStaticContent`,
  subscriptionAdd: `${url}/subscription/addSubscriptionPlan`,
  allSubscriptionPlan: `${url}/subscription/getAllSubscriptionPlan`,
  updateSubscriptionPlan: `${url}/subscription/updateSubscriptionPlan`,

  getAllApps: `${url}/user/listAllApps`,
  getAppAllDetails: `${url}/admin/viewAppAllDetails`,
  blockApp: `${url}/user/updateAppKeyDetails`,

  //contact
  getAllContactUs: `${url}/contactUs/getAllContactUs`,
  queryReply: `${url}/contactUs/queryReply`,
};
export default ApiConfig;
